import { render, h, Component } from "preact";

let did_run_start = false;
let sublime_div = null;

class Sublime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      embed_id: this.props.embed_id,
      ad: null,
      nice_url: this.props.container?.hasAttribute("data-nice-url"),
      title_first: this.props.container?.hasAttribute("data-title-first"),
      content_together: this.props.container?.hasAttribute("data-content-together"),
      no_store: this.props.container?.hasAttribute("data-no-store"),
      track_views: !this.props.container?.hasAttribute("data-disable-track-views"),
      disable_resizer: this.props.container?.hasAttribute("data-disable-resizer"),
      resize_width: this.props.container?.getAttribute("data-resize-width") !== null ? this.props.container?.getAttribute("data-resize-width") : 300,
      resize_height: this.props.container?.getAttribute("data-resize-height") !== null ? this.props.container?.getAttribute("data-resize-height") : "auto",
      resize_quality: this.props.container?.getAttribute("data-resize-quality") !== null ? this.props.container?.getAttribute("data-resize-quality") : 95,
    };
    this.did_track_view = false;
  }

  componentDidMount = () => {
    const stored_ad = sessionStorage.getItem("sublime_embed_✌️");
    if (
      stored_ad !== null &&
      stored_ad.ad !== null &&
      !this.state.no_store &&
      !this.props.container?.hasAttribute("data-no-store")
    ) {
      this.setState(JSON.parse(stored_ad), () => {
        this._set_up_view_tracking();
      });
    } else if (this.state.embed_id !== null) {
      sessionStorage.removeItem("sublime_embed_✌️");
      this._fetch_ad();
    }
    this._clean_up_container();
  };

  _clean_up_container = () => {
    this.props.container?.removeAttribute("sublime-embed") ||
      this.props.container?.removeAttribute("data-sublime-embed");
    this.props.container?.classList.add("sublime_embed");
    this.props.container?.removeAttribute("data-nice-url");
    this.props.container?.removeAttribute("data-content-together");
    this.props.container?.removeAttribute("data-no-store");
    this.props.container?.removeAttribute("data-title-first");
    this.props.container?.removeAttribute("data-track-views");
    this.props.container?.removeAttribute("data-disable-resizer");
    this.props.container?.removeAttribute("data-resize-width");
    this.props.container?.removeAttribute("data-resize-height");
    this.props.container?.removeAttribute("data-resize-quality");
    this.props.container ? (this.props.container.innerHTML = null) : null;
  };

  _nice_ad = (ad_object) => {
    return {
      title: ad_object?.title,
      content: ad_object?.content,
      url: ad_object?.url,
      public_id: ad_object?.public_id,
      redirect_to_url: ad_object?.redirect_to_url,
      image_url: ad_object?.image_url != null ? ad_object?.image_url : null,
    };
  };

  _fetch_ad = async () => {
    fetch(`https://sublimeads.com/api/embed/${this.state.embed_id}`).then(
      (response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            if (result.ad !== null) {
              this.setState({ ad: this._nice_ad(result.ad) }, () => {
                if (!this.state.no_store) {
                  sessionStorage.setItem(
                    "sublime_embed_✌️",
                    JSON.stringify(this.state)
                  );
                }
                this._set_up_view_tracking();
              });
            }
          });
        }
      }
    );
  };

  _set_up_view_tracking = () => {
    if (this.state.track_views) {
      this._check_ad_is_visible();
      window.onscroll = () => {
        if (!this.did_track_view) {
          this._check_ad_is_visible();
        }
      };
    }
  };

  _check_ad_is_visible = () => {
    var rect = sublime_div.getBoundingClientRect();
    var view_height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const is_in_view = !(rect.bottom < 0 || rect.top - view_height >= 0);
    if (is_in_view && !this.did_track_view) {
      this._track_ad_view();
    }
  };

  _track_ad_view = () => {
    this.did_track_view = true;
    fetch(
      `https://sublimeads.com/api/embed/view/?token=${this.state.embed_id}&id=${this.state.ad.public_id}`,
      { method: "POST" }
    );
  };

  render() {
    if (this.state.ad) {
      const item = this.state.ad;
      const url = this.state.nice_url ? item.redirect_to_url : item.url;
      return (
        <div class="sublime_embed-inner">
          <div class="sublime_embed-intro">
            {this.state.title_first && !this.state.content_together ? (
              <a href={url} title={item.title} target="_blank" rel="noopener">
                <h4>{item.title}</h4>
              </a>
            ) : null}
            {item.image_url !== null ? (
              <a href={url} title={item.title} target="_blank" rel="noopener">
                <img
                  src={this.state.disable_resizer ? item.image_url : `${item.image_url}?width=${this.state.resize_width}&height=${this.state.resize_height}&quality=${this.state.resize_quality}`}
                  style="max-width: 100%"
                  loading="lazy"
                />
              </a>
            ) : null}
            {!this.state.title_first && !this.state.content_together ? (
              <a href={url} title={item.title} target="_blank" rel="noopener">
                <h4>{item.title}</h4>
              </a>
            ) : null}
          </div>
          <a href={url} title={item.title} target="_blank" rel="noopener">
            {this.state.content_together ? <h4>{item.title}</h4> : null}
            <content
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></content>
          </a>
        </div>
      );
    }
  }
}

const start_app = () => {
  sublime_div =
    document.querySelector("[sublime-embed]") ||
    document.querySelector("[data-sublime-embed]");
  if (sublime_div !== null) {
    did_run_start = true;
    const existing_sublime_div = document.querySelector(".sublime_embed-inner");
    if (existing_sublime_div === null) {
      const embed_id =
        sublime_div.getAttribute("sublime-embed") ||
        sublime_div.getAttribute("data-sublime-embed");
      if (embed_id !== null && embed_id !== undefined && embed_id !== "") {
        render(
          <Sublime embed_id={embed_id} container={sublime_div} />,
          sublime_div
        );
      }
    }
  }
};

start_app();

document.addEventListener("DOMContentLoaded", () => {
  if (!did_run_start) {
    start_app();
  }
});
